import React from 'react';

import * as css from "./ExportPage.module.scss";

type Props = {
  className?: string;
};

const ExportPage: React.FC<Props> = ({ className }) => {
  return (
    <div className={[css.exportPage, className || ''].join(' ')}>
      <h1>Export</h1>
      <p>@TODO</p>
    </div>
  );
};

export default ExportPage;
