import React from 'react';
import { Link } from 'react-router-dom';

import iconCardList from 'bootstrap-icons/icons/card-list.svg';
import iconCardHeading from 'bootstrap-icons/icons/card-heading.svg';
import iconFileEarmarkArrowDown from 'bootstrap-icons/icons/file-earmark-arrow-down.svg';
import iconMinusSquare from 'bootstrap-icons/icons/dash-square.svg';
import iconPencil from 'bootstrap-icons/icons/pencil.svg';
import iconPlusSquare from 'bootstrap-icons/icons/plus-square.svg';
import iconSave from 'bootstrap-icons/icons/save.svg';
import iconXLg from 'bootstrap-icons/icons/x-lg.svg';

import * as css from "./IconButton.module.scss";

type ButtonType = 'button' | 'submit' | 'reset';

type Props = {
  iconText?: string;
  iconType?: string;
  iconSize?: string;
  buttonType?: ButtonType;
  inline?: boolean;
  disabled?: boolean;
  to?: string;
  href?: string;
  onClick?: (_e: MouseEvent) => void;
  className?: string;
};

const IconButton: React.FC<Props> = ({
  iconText,
  iconType,
  iconSize = 'lg',
  inline = false,
  disabled = false,
  buttonType = ('button' as ButtonType),
  to = '',
  href = '',
  onClick = () => {},
  className,
}) => {
  const iconClass = [css.icon, iconSize === 'sm' ? css.small : ''].join(' ');
  const icon = (
    <>
      { iconType === 'add' && (
        <img src={iconPlusSquare} alt="" className={iconClass} />
      )}
      { iconType === 'cancel' && (
        <img src={iconXLg} alt="" className={iconClass} />
      )}
      { iconType === 'card-list' && (
        <img src={iconCardList} alt="" className={iconClass} />
      )}
      { iconType === 'card-view' && (
        <img src={iconCardHeading} alt="" className={iconClass} />
      )}
      { iconType === 'delete' && (
        <img src={iconMinusSquare} alt="" className={iconClass} />
      )}
      { iconType === 'download' && (
        <img src={iconFileEarmarkArrowDown} alt="" className={iconClass} />
      )}
      { iconType === 'edit' && (
        <img src={iconPencil} alt="" className={iconClass} />
      )}
      { iconType === 'save' && (
        <img src={iconSave} alt="" className={iconClass} />
      )}
    </>
  );
  const cssClasses = [css.iconButton, inline ? css.inline : '', className || ''];
  const text = <span className={css.text}>{ iconText }</span>;
  if (to.length) {
    return (
      <Link
        className={cssClasses.join(' ')}
        to={to}
        disabled={disabled}
      >
        {icon}
        {text}
      </Link>
    );
  }
  if (href.length) {
    return (
      <a
        className={cssClasses.join(' ')}
        href={href}
        disabled={disabled}
      >
        {icon}
        {text}
      </a>
    );
  }
  return (
    <button
      type={buttonType}
      className={cssClasses.join(' ')}
      onClick={onClick}
      disabled={disabled}
    >
      {icon}
      {text}
    </button>
  );
};

export default IconButton;
