import React from 'react';
import ReactDOM from 'react-dom';
import {
  BrowserRouter,
  Routes,
  Route,
} from "react-router-dom";

import ActivityDeletePage from './components/ActivityDeletePage';
import ActivityEditPage from './components/ActivityEditPage';
import ActivityItemPage from './components/ActivityItemPage';
import ActivityListPage from './components/ActivityListPage';
import AppContainer from './components/AppContainer';
import ExportPage from './components/ExportPage';
import NotFoundPage from './components/NotFoundPage';

import { DataContextProvider } from './lib/DataContext';

const App = () => {
  return (
    <DataContextProvider>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<AppContainer />}>
            <Route index element={(
              <ActivityListPage />
            )} />
            <Route path="activity" element={(
              <ActivityEditPage />
            )} />
            <Route path="activity/:id" element={(
              <ActivityItemPage />
            )} />
            <Route path="activity/:id/edit" element={(
              <ActivityEditPage />
            )} />
            <Route path="activity/:id/delete" element={(
              <ActivityDeletePage />
            )} />
            <Route path="export" element={(
              <ExportPage />
            )} />
            <Route path="*" element={(
              <NotFoundPage />
            )} />
          </Route>
        </Routes>
      </BrowserRouter>
    </DataContextProvider>
  );
};

window.onload = () => {
  ReactDOM.render(
    <App />,
    document.getElementById('root'),
  );
};
