import React from 'react';

import * as css from "./NotFoundPage.module.scss";

type Props = {
  className?: string;
};

const NotFoundPage: React.FC<Props> = ({ className }) => {
  return (
    <div className={[css.notFoundPage, className || ''].join(' ')}>
      <h1>Page not found</h1>
      <p>:(</p>
    </div>
  );
};

export default NotFoundPage;
