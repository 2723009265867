import React from 'react';
import {
  Link,
} from "react-router-dom";
import Moment from 'moment';

import { formatTime, timespansTotal } from '../../lib/Time';
import { Activity } from '../../lib/Types';

import * as css from "./ActivityListItem.module.scss";

type Props = {
  activity: Activity;
  className?: string;
};

const ActivityListItem: React.FC<Props> = ({ activity, className }) => {
  return (
    <Link
      to={`/activity/${activity.id}`}
      className={[css.activityListItem, className || ''].join(' ')}
    >
      <div>
        {Moment(activity.date).format('dddd MMMM Do YYYY')}
      </div>
      <div className={css.total}>
        {formatTime(timespansTotal(activity?.timespans || []))}
      </div>
    </Link>
  );
};

export default ActivityListItem;
