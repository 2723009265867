.navbar {
  display: flex;
  flex-direction: row;

  .navbarItem {
    flex: 0 0 auto;
    margin: 0 0.8rem;

    &:first-child {
      margin-left: 0;
    }

    &:last-child {
      margin-right: 0;
    }
  }
}
