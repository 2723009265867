import Moment from 'moment';

import { TimeInput, TimeSpan } from './Types';

const DEFAULT_TIMESPAN: TimeInput = { hours: 0, minutes: 0 };

export const formatTime = (timespan: TimeInput): string => {
  return Moment(`${timespan?.hours || 0}:${timespan?.minutes || 0}`, 'HH:mm').format('HH:mm');
};

export const timespanLength = (start: TimeInput, end: TimeInput) => {
  const { hours: startHours = 0, minutes: startMinutes = 0 } = start || {};
  const { hours: endHours = 0, minutes: endMinutes = 0 } = end || {};
  const adjustHour = (endMinutes < startMinutes)
    ? -1
    : 0;
  return {
    hours: (endHours - startHours) + adjustHour,
    minutes: Math.abs(endMinutes - startMinutes),
  };
};

export const timespansTotal = (timespans: TimeSpan[] = []): TimeInput => {
  return (timespans || []).reduce((agg, timespan) => {
    const { hours, minutes } = timespanLength(timespan.start, timespan.end);
    agg.minutes += (minutes || 0);
    if (agg.minutes > 60) {
      agg.hours += Math.floor(agg.minutes / 60);
      agg.minutes = agg.minutes % 60;
    }
    agg.hours += (hours || 0);
    return agg;
  }, { hours: 0, minutes: 0 })
};
