import React from 'react';

import * as css from "./Navbar.module.scss";

type Props = {
  className?: string;
  children?: React.ReactNode;
};

const Navbar: React.FC<Props> = ({ className, children }) => {
  const mappedChildren = React.Children.map(
    children,
    (child) => {
      if (child) {
        return React.cloneElement(child, { className: css.navbarItem });
      }
      return null;
    },
  );
  return (
    <nav className={[css.navbar, className || ''].join(' ')}>
      { mappedChildren }
    </nav>
  );
};

export default Navbar;
