import React from 'react';
import { useParams } from "react-router-dom";
import Moment from 'moment';

import EntryDetails from '../EntryDetails';
import IconButton from '../IconButton';
import Navbar from '../Navbar';

import NotFoundPage from '../NotFoundPage';

import { ACTIVITY_ENTRY_TYPES, activityEntryInfo } from '../../lib/Activity';
import { useDatabaseBackedData } from '../../lib/DataContext';
import { formatTime, timespanLength, timespansTotal } from '../../lib/Time';

import * as css from "./ActivityItemPage.module.scss";

type Props = {
  className?: string;
};

const ActivityItemPage: React.FC<Props> = ({ className }) => {
  const { state, dispatch } = useDatabaseBackedData();
  const params = useParams();

  React.useEffect(() => {
    dispatch({
      type: 'get_activity',
      id: params.id,
    });
  }, [params]);

  const activity = state?.currentActivity;
  const hasActivity = Boolean(activity);

  if (!hasActivity) {
    return <NotFoundPage />;
  }

  const entries = activity?.entries || {};
  const entryInfo = Object.keys(entries).reduce((agg, key) => {
    return { ...agg, [key]: activityEntryInfo(key, activity, state?.activities || []) };
  }, {});

  return (
    <div className={[css.activityItemPage, className || ''].join(' ')}>
      <h1>{Moment(activity.date).format('dddd MMMM Do YYYY')}</h1>
      <Navbar>
        <IconButton to={`/activity/${params.id}/edit`} iconType="edit" iconText="Edit" />
      </Navbar>
      <p>
        <strong>ID:</strong>{' '}
        {params.id}
      </p>
      <p>
        <strong>Timespans:</strong>{' '}
      </p>
      <ul>
        {(activity?.timespans || []).map((ts, index) => (
          <li key={index}>
            {formatTime(ts.start)} - {formatTime(ts.end)} => {formatTime(timespanLength(ts.start, ts.end))}
          </li>
        ))}
      </ul>
      <p>
        <strong>Total working time:</strong>{' '}
        {formatTime(timespansTotal(activity?.timespans || []))}
      </p>
      <ol>
        {Object.keys(ACTIVITY_ENTRY_TYPES).map((entryKey) => (
          entryInfo[entryKey] && (
            <li key={entryKey}>
              <strong>{entryInfo[entryKey].title}</strong> {entryInfo[entryKey].subtitle}
              <EntryDetails entries={entries[entryKey]} />
            </li>
          )
        ))}
      </ol>
    </div>
  );
};

export default ActivityItemPage;
