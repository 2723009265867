import React from 'react';
import {
  useParams,
  useNavigate,
  Link,
} from "react-router-dom";
import Moment from 'moment';

import ComponentFactory from '../ComponentFactory';
import DateEditor from '../DateEditor';
import IconButton from '../IconButton';
import Navbar from '../Navbar';
import NotFoundPage from '../NotFoundPage';
import TimeSpanEditor from '../TimeSpanEditor';

import { useDatabaseBackedData } from '../../lib/DataContext';
import { Activity } from '../../lib/Types';

import * as css from "./ActivityEditPage.module.scss";

type Props = {
  className?: string;
};

const ActivityEditPage: React.FC<Props> = ({ className }) => {
  const { state, dispatch } = useDatabaseBackedData();
  const params = useParams();
  const navigate = useNavigate();
  const [currentActivity, setCurrentActivity] = React.useState<Activity>({});
  const [pendingSave, setPendingSave] = React.useState<boolean>(false);

  React.useEffect(() => {
    dispatch({
      type: 'get_activity',
      id: params.id,
    });
  }, [params]);

  const activity = state?.currentActivity;
  const hasActivity = Boolean(activity);

  React.useEffect(() => {
    setCurrentActivity(activity);
  }, [activity]);

  React.useEffect(() => {
    if (pendingSave) {
      navigate(`/activity/${currentActivity.id}`);
    }
  }, [pendingSave, state.lastUpdate]);

  if (params.id && !hasActivity) {
    return <NotFoundPage />;
  }

  const saveActivity = (e) => {
    e.preventDefault();
    setPendingSave(true);
    if (params.id) {
      dispatch({
        type: 'update_activity',
        id: params.id,
        payload: currentActivity,
      });
    } else {
      dispatch({
        type: 'add_activity',
        payload: currentActivity,
      });
    }
  };

  let dateValue = '';
  if (currentActivity?.date) {
    dateValue = Moment(currentActivity?.date).format('YYYY-MM-DD');
  }

  return (
    <div className={[css.activityEditPage, className || ''].join(' ')}>
      <h1>Edit activity</h1>
      <Navbar>
        <IconButton to={`/activity/${params.id}`} iconType="card-view" iconText="View" />
      </Navbar>
      <form onSubmit={saveActivity}>
        <h2>Date</h2>
        <DateEditor value={dateValue} required onChange={(date) => {
          const newActivity = { ...currentActivity, date };
          setCurrentActivity(newActivity);
        }} />
        <h2>Timespans</h2>
        <ul className={css.timespanList}>
          {(currentActivity?.timespans || []).map((span, index) => (
            <TimeSpanEditor
              timeSpan={span}
              key={index}
              index={index}
              onChange={(newTimeSpan, timeSpanIndex) => {
                const newActivity = { ...currentActivity };
                newActivity.timespans[timeSpanIndex] = newTimeSpan;
                setCurrentActivity(newActivity);
              }}
            />
          ))}
        </ul>
        <button
          type="button"
          onClick={() => {
            const newActivity = {
              ...currentActivity,
              timespans: (currentActivity?.timespans || []).concat({}),
            };
            setCurrentActivity(newActivity);
          }}
        >
          Add timespan
        </button>

        <h2>Yesterday</h2>
        <ComponentFactory
          type="TextEntryChildren"
          elemProps={{
            items: currentActivity?.entries?.yesterday || [],
            onChange: (newEntries) => {
              const newActivity = {
                ...currentActivity,
                entries: {
                  ...currentActivity.entries,
                  yesterday: newEntries,
                },
              };
              setCurrentActivity(newActivity);
            },
          }}
        />

        <h2>Today</h2>
        <ComponentFactory
          type="TextEntryChildren"
          elemProps={{
            items: currentActivity?.entries?.today || [],
            onChange: (newEntries) => {
              const newActivity = {
                ...currentActivity,
                entries: {
                  ...currentActivity.entries,
                  today: newEntries,
                },
              };
              setCurrentActivity(newActivity);
            },
          }}
        />

        <h2>Additional</h2>
        <ComponentFactory
          type="TextEntryChildren"
          elemProps={{
            items: currentActivity?.entries?.additional || [],
            onChange: (newEntries) => {
              const newActivity = {
                ...currentActivity,
                entries: {
                  ...currentActivity.entries,
                  additional: newEntries,
                },
              };
              setCurrentActivity(newActivity);
            },
          }}
        />

        <h2>Blockers</h2>
        <ComponentFactory
          type="TextEntryChildren"
          elemProps={{
            items: currentActivity?.entries?.blocker || [],
            onChange: (newEntries) => {
              const newActivity = {
                ...currentActivity,
                entries: {
                  ...currentActivity.entries,
                  blocker: newEntries,
                },
              };
              setCurrentActivity(newActivity);
            },
          }}
        />

        <h2>Working</h2>
        <ComponentFactory
          type="TextEntryChildren"
          elemProps={{
            items: currentActivity?.entries?.working || [],
            onChange: (newEntries) => {
              const newActivity = {
                ...currentActivity,
                entries: {
                  ...currentActivity.entries,
                  working: newEntries,
                },
              };
              setCurrentActivity(newActivity);
            },
          }}
        />

        <Navbar>
          <IconButton iconType="save" iconText="Save" buttonType="submit" />
          {hasActivity && (
            <IconButton to={`/activity/${params.id}`} iconType="cancel" iconText="Cancel" />
          )}
          {!hasActivity && (
            <IconButton to={`/`} iconType="cancel" iconText="Cancel" />
          )}
        </Navbar>
      </form>
    </div>
  );
};

export default ActivityEditPage;
