import React from 'react';
import {
  Link,
  useNavigate,
} from "react-router-dom";

import ActivityListItem from '../ActivityListItem';
import IconButton from '../IconButton';
import Navbar from '../Navbar';

import { useDatabaseBackedData } from '../../lib/DataContext';

import * as css from "./ActivityListPage.module.scss";

type Props = {
  className?: string;
};

const ActivityListPage: React.FC<Props> = ({ className }) => {
  const navigate = useNavigate();
  const { state } = useDatabaseBackedData();

  const activityList = (state?.activities || []);
  activityList.sort((a, b) => {
    return b.date.getTime() - a.date.getTime();
  });

  return (
    <div className={[css.activityListPage, className || ''].join(' ')}>
      <h1>Activity list</h1>
      <Navbar>
        <IconButton
          iconType="add"
          iconText="Add activity"
          onClick={() => navigate('/activity')}
        />
      </Navbar>
      <ul className={css.activityList}>
        {activityList.map((activity) => (
          <li key={activity.id} className={css.activityItem}>
            <ActivityListItem activity={activity} />
          </li>
        ))}
      </ul>
      <p>Page {(state?.activityPage || 0) + 1}</p>
    </div>
  );
};

export default ActivityListPage;
