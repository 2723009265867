import React from 'react';

import * as css from "./DateEditor.module.scss";

type Props = {
  value?: string;
  required?: boolean;
  onChange?: (_date: string) => void;
  className?: string;
};

const DateEditor: React.FC<Props> = ({ value, required = false, onChange, className }) => {
  const [currentValue, setCurrentValue] = React.useState(value);

  React.useEffect(() => {
    setCurrentValue(value);
  }, [value]);

  return (
    <div className={[css.dateEditor, className || ''].join(' ')}>
      <input
        type="date"
        value={currentValue || ''}
        required={required}
        onChange={(e) => {
          setCurrentValue(e.target.value);
          if (typeof onChange === 'function') {
            onChange(e.target.value);
          }
        }}
      />
    </div>
  );
};

export default DateEditor;
