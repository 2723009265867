.appContainer {
  display: block;
}

.header {
  padding: 0.8rem;
  background: #ddd;

  .h1 {
    margin: 0;
  }
}

.nav {
  display: flex;
  flex-direction: row;

  .navItem {
    flex: 0 0 auto;
    margin: 0 0.8rem;

    &:first-child {
      margin-left: 0;
    }

    &:last-child {
      margin-right: 0;
    }
  }
}

.main {
  padding: 0.8rem;
}
