import React from 'react';

import IconButton from '../IconButton';
import TimeInputEditor from '../TimeInputEditor';

import { timespanLength } from '../../lib/Time';
import { TimeInput, TimeSpan } from '../../lib/Types';

import * as css from "./TimeSpanEditor.module.scss";

type Props = {
  timeSpan?: TimeSpan;
  index?: number;
  className?: string;
  onChange?: (_timeSpan: TimeSpan) => void;
  onRemove?: (_index: number) => void;
};

const TimeSpanEditor: React.FC<Props> = ({ timeSpan, index, onChange, onRemove, className }) => {
  const [currentTimeSpan, setCurrentTimeSpan] = React.useState(timeSpan);
  const [spanEstimate, setSpanEstimate] = React.useState<TimeInput>({ hours: 0, minutes: 0 });

  React.useEffect(() => {
    setCurrentTimeSpan(timeSpan);
  }, [timeSpan]);

  React.useEffect(() => {
    const newSpanEstimate = timespanLength(currentTimeSpan?.start, currentTimeSpan?.end);
    setSpanEstimate(newSpanEstimate);
  }, [currentTimeSpan]);

  return (
    <div className={[css.timeSpanEditor, className || ''].join(' ')}>
      <TimeInputEditor
        timeInput={currentTimeSpan?.start}
        onChange={(newStart) => {
          const newTimeSpan = { ...currentTimeSpan, start: newStart };
          setCurrentTimeSpan(newTimeSpan);
          if (typeof onChange === 'function') {
            onChange(newTimeSpan, index);
          }
        }}
      />
      {' '}➔{' '}
      <TimeInputEditor
        timeInput={currentTimeSpan?.end}
        onChange={(newEnd) => {
          const newTimeSpan = { ...currentTimeSpan, end: newEnd };
          setCurrentTimeSpan(newTimeSpan);
          if (typeof onChange === 'function') {
            onChange(newTimeSpan, index);
          }
        }}
      />
      <span>
        {spanEstimate?.hours || 0} : {spanEstimate?.minutes || 0}
      </span>
      <IconButton iconType="delete" iconText="Remove" inline iconSize="sm" onClick={(e) => {
        e.preventDefault();
        if (typeof onRemove === 'function') {
          onRemove(index);
        }
      }} />
    </div>
  );
};

export default TimeSpanEditor;
