.iconButton {
  display: flex;
  flex-direction: column;
  text-align: center;
  opacity: 1;
  appearance: button;

  &[disabled] {
    opacity: 0.4;
  }

  &.inline {
    display: inline-flex;
    flex-direction: row;

    .icon {
      margin-right: 0.4rem;
    }
  }
}

.icon {
  display: block;
  width: 2.4rem;
  height: 2.4rem;
  margin: 0 auto;

  &.medium {
    width: 1.6rem;
    height: 1.6rem;
  }

  &.small {
    width: 1.0rem;
    height: 1.0rem;
  }
}

.text {
  font-size: 0.8rem;
  font-weight: 400;
}
