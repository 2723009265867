.activityEditPage {
  display: block;
}

.timespanList {
  margin: 0;
  list-style: none;
}

.nav {
  display: flex;
  flex-direction: row;

  .navItem {
    flex: 0 0 auto;
    margin: 0 0.8rem;

    &:first-child {
      margin-left: 0;
    }

    &:last-child {
      margin-right: 0;
    }
  }
}
