import React from 'react';

import TextEntryChildren from '../TextEntryChildren';
import TextEntryEditor from '../TextEntryEditor';

type Props = {
  type: string;
  elemProps?: any;
};

const ComponentFactory: React.FC<Props> = ({ type, elemProps = {} }) => {
  if (type === 'TextEntryEditor') {
    return (
      <TextEntryEditor
        getChildrenEditor={({ currentEntry, index, setCurrentEntry, onChange }) => (
          <ComponentFactory
            type="TextEntryChildren"
            elemProps={{
              items: currentEntry?.children || [],
              onChange: (newChildren) => {
                const newEntry = { ...currentEntry, children: newChildren };
                setCurrentEntry(newEntry);
                if (typeof onChange === 'function') {
                  onChange(newEntry, index);
                }
              },
            }}
          />
        )}
        {...elemProps}
      />
    );    
  }
  if (type === 'TextEntryChildren') {
    return (
      <TextEntryChildren
        getTextEditor={({ child, index, currentItems, setCurrentItems, onChange }) => (
          <ComponentFactory
            type="TextEntryEditor"
            elemProps={{
              entry: child,
              index, 
              onChange: (newChild, childIndex) => {
                const newCurrentItems = currentItems;
                newCurrentItems[childIndex] = newChild;
                setCurrentItems(newCurrentItems);
                if (typeof onChange === 'function') {
                  onChange(newCurrentItems);
                }
              },
              onRemove: (index) => {
                const newCurrentItems = currentItems.splice(index, 1);
                setCurrentItems(newCurrentItems);
                if (typeof onChange === 'function') {
                  onChange(newCurrentItems);
                }
              },
            }}
          />
        )}
        {...elemProps}
      />
    ); 
  }
  return <></>;
};

export default ComponentFactory;
