import { v4 as uuidv4 } from 'uuid';

const DATABASE_NAME = 'ITS_A_LIVING';
const DATABASE_VERSION = 1;
const DATABASE_TABLE_INFO = {
  activities: {
    keyPath: 'id',
    autoIncrement: false, // Use UUID instead.
  },
};

class DBService {
  protected async openDB(): Promise<IDBDatabase> {
    if (!('indexedDB' in window)) {
      throw new Error(`This browser doesn't support IndexedDB.`);
    }
    console.log('opening db');
    return new Promise((resolve, reject) => {
      const idbOpenDbRequest = window.indexedDB.open(DATABASE_NAME, DATABASE_VERSION);
      idbOpenDbRequest.onerror = (err) => {
        console.error(err);
        reject(err);
      };
      idbOpenDbRequest.onsuccess = () => {
        console.log('onsuccess');
        resolve(idbOpenDbRequest.result);
      };
      idbOpenDbRequest.onupgradeneeded = () => {
        const db = idbOpenDbRequest.result;
        db.onerror = (event) => {
          console.error(event);
        };
        const newVersion = db.version;
        if (newVersion > 0) {
          if (!db.objectStoreNames.contains('activities')) {
            console.log('Creating store activities...');
            const store = db.createObjectStore('activities', DATABASE_TABLE_INFO.activities);
            store.createIndex('id', 'id', { unique: true });
          }
        }
      };
    });
  }

  async callDbTransaction(transaction, func, params = []) {
    console.log('callDbTransaction', transaction, func, params);
    return new Promise((resolve, reject) => {
      const result = transaction[func](...params);
      result.onsuccess = () => {
        resolve(result.result);
      };
      result.onerror = (e) => {
        reject(e);
      };
    });
  }

  async get(tablespace, key) {
    console.log('get');
    try {
      const db = await this.openDB();
      console.log('db', db);
      const transaction = db.transaction(tablespace);
      const store = transaction.objectStore(tablespace);
      console.log('store', store);
      const result = await this.callDbTransaction(store, 'get', [key]);
      return this.unmarshal(tablespace, result);
    } catch (e) {
      console.error(e);
    }
  }

  async getAll(tablespace, indexName = null, index = null) {
    console.log('getAll');
    // try {
      const db = await this.openDB();
      console.log('db', db);
      const transaction = db.transaction(tablespace);
      const store = transaction.objectStore(tablespace);
      console.log('store', store);
      let results;
      if (indexName) {
        const indexObject = store.index(indexName);
        results = await this.callDbTransaction(indexObject, 'getAll', [index]);
      } else {
        results = await this.callDbTransaction(store, 'getAll', []);
      }
      return results.map((result) => this.unmarshal(tablespace, result));
    // } catch (e) {
    //   console.error(e);
    // }
  }

  async put(tablespace, object, key = null) {
    return this.openDB().then(db => {
      const marshalled = this.marshal(tablespace, object);
      const transaction = db.transaction(tablespace, 'readwrite');
      const store = transaction.objectStore(tablespace);
      if (key) {
        return this.callDbTransaction(store, 'put', [marshalled, key]);
      }
      return this.callDbTransaction(store, 'put', [marshalled]);
    }).catch(error => {
      console.error(error);
    });
  }

  async delete(tablespace, key) {
    return this.openDB().then(db => {
      const transaction = db.transaction(tablespace, 'readwrite');
      const store = transaction.objectStore(tablespace);
      return this.callDbTransaction(store, 'delete', [key]);
    }).catch(error => {
      console.error(error);
    });
  }

  marshal(tablespace: string, payload: any = {}) {
    const tableInfo = DATABASE_TABLE_INFO[tablespace];
    if (tablespace === 'activities') {
      const id = payload[tableInfo.keyPath] || uuidv4();
      return {
        id,
        date: payload?.date ? new Date(payload.date) : null,
        timespans: JSON.stringify(payload?.timespans || []),
        entries: JSON.stringify(payload?.entries || {}),
      };
    }
    return { ...payload };
  }

  unmarshal(tablespace: string, payload: any = {}) {
    if (tablespace === 'activities') {
      return {
        id: payload?.id || null,
        date: payload?.date ? new Date(payload.date) : null,
        timespans: JSON.parse(payload?.timespans || '[]'),
        entries: JSON.parse(payload?.entries || '{}'),
      };
    }
    return payload;
  }
}

const DBServiceSingleton = new DBService();
export default DBServiceSingleton;
