import React, { createContext, useReducer } from 'react';
import { v4 as uuidv4 } from 'uuid';

import DBService from './LocalDatabaseService';
import { GlobalState } from './Types';

const DataContext = createContext();
const initialData = {};
const initData = (payload: Partial<GlobalState> = {}): GlobalState => ({
  ...initialData,
  ...payload,
  lastUpdate: new Date(),
});

const dataReducer = (state: GlobalState, { type, id, payload }): GlobalState => {
  switch (type) {
    case 'get_activity':
      return {
        ...state,
        currentActivity: payload,
        lastUpdate: new Date(),
      };

    case 'add_activity':
      if (id) {
        return {
          ...state,
          currentActivity: (payload || {}),
          lastUpdate: new Date(),
        };
      }
      return state;

    case 'update_activity':
      if (id && payload) {
        return {
          ...state,
          currentActivity: (payload || {}),
          lastUpdate: new Date(),
        };
      }
      return state;

    case 'delete_activity':
      if (id) {
        return {
          ...state,
          currentActivity: undefined,
          lastUpdate: new Date(),
        };
      }
      return state;

    case 'reset':
      return initData(payload);

    default:
      return state;
  }
}

export const useData = () => {
  const context = React.useContext(DataContext);

  if (!context) {
    throw new Error(
      `useData must be used within DataContextProvider`
    );
  }

  const [state, dispatch] = context;
  return { state, dispatch };
};

type DispatchRequest = {
  type: string;
  id?: string;
  payload?: any;
};

export const useDatabaseBackedData = () => {
  const { state, dispatch } = useData();

  React.useEffect(() => {
    console.log('initialise useDatabaseBackedData');
    DBService.getAll('activities', 'date')
      .then((results) => {
        dispatch({
          type: 'reset',
          payload: {
            activities: results,
            activityPage: 0,
          },
        });
      });
  }, []);

  const dbDispatch = async ({ type, id = null, payload = {} }: DispatchRequest): Promise<void> => {
    // console.log({ type, id, payload });
    switch (type) {
      case 'get_activity':
        const activity = await DBService.get('activities', id);
        if (!activity) {
          throw new Error(`Activity ${id} not found`);
        }
        dispatch({ type, id: activity.id, payload: activity });
        break;

      case 'add_activity':
        const newId = uuidv4();
        const newPayload = { ...payload, id: newId };
        await DBService.put('activities', newPayload);
        dispatch({ type, id: newId, payload: newPayload });
        break;

      case 'update_activity':
        await DBService.put('activities', payload);
        dispatch({ type, id, payload });
        break;

      case 'delete_activity':
        await DBService.delete('activities', id);
        dispatch({ type, id, payload });
        break;

      case 'reset':
        const activities = await DBService.getAll('activities');
        dispatch({ type, payload: activities });
        break;

      default:
        dispatch({ type, id, payload });
        break;
    }
  };

  return { state, dispatch: dbDispatch };
};

export const DataContextProvider = (props) => {
  const [state, dispatch] = useReducer(dataReducer, initialData, initData);
  const value = React.useMemo(() => [state, dispatch], [state]);
  return <DataContext.Provider value={value} {...props} />;
};
