import React from 'react';
import {
  Outlet,
  Link,
} from "react-router-dom";

import IconButton from '../IconButton';
import Navbar from '../Navbar';

import * as css from "./AppContainer.module.scss";

type Props = {
  className?: string;
};

const AppContainer: React.FC<Props> = ({ className }) => {
  return (
    <div className={[css.appContainer, className || ''].join(' ')}>
      <header className={css.header}>
        <h1 className={css.h1}>It's a living</h1>
        <Navbar>
          <IconButton to="/" iconType="card-list" iconText="Activity List" />
          <IconButton to="/export" iconType="download" iconText="Export" />
        </Navbar>
      </header>
      <main className={css.main}>
        <Outlet />
      </main>
    </div>
  );
};

export default AppContainer;
