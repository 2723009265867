import React from 'react';

import IconButton from '../IconButton';

import { NestedTextEntry } from '../../lib/Types';

import * as css from "./TextEntryEditor.module.scss";

type Props = {
  className?: string;
  index?: number;
  entry?: NestedTextEntry;
  getChildrenEditor?: (_props: any) => React.ReactNode;
  onChange?: (_entry: NestedTextEntry, _index: number) => void;
  onRemove?: (_index: number) => void;
};

const TextEntryEditor: React.FC<Props> = ({ entry = {}, index, getChildrenEditor, onChange, onRemove, className }) => {
  const [currentEntry, setCurrentEntry] = React.useState(entry);

  React.useEffect(() => {
    setCurrentEntry(entry);
  }, [entry]);

  return (
    <li className={[css.textEntryEditor, className || ''].join(' ')}>
      <input
        type="text"
        value={currentEntry?.text || ''}
        onChange={(e) => {
          const newEntry = { ...entry, text: e.target.value };
          setCurrentEntry(newEntry);
          if (typeof onChange === 'function') {
            onChange(newEntry, index);
          }
        }}
      />
      <IconButton iconType="delete" iconText="Remove" inline iconSize="sm" onClick={(e) => {
        e.preventDefault();
        if (typeof onRemove === 'function') {
          onRemove(index);
        }
      }} />
      {typeof getChildrenEditor === 'function' && getChildrenEditor({
        currentEntry, index, setCurrentEntry, onChange,
      })}
    </li>
  );
};

export default TextEntryEditor;
