import React from 'react';
import {
  useParams,
  useNavigate,
  Link,
} from "react-router-dom";

import NotFoundPage from '../NotFoundPage';

import { useDatabaseBackedData } from '../../lib/DataContext';

import * as css from "./ActivityDeletePage.module.scss";

type Props = {
  className?: string;
  children?: React.ReactNode;
};

const ActivityDeletePage: React.FC<Props> = ({ className, children }) => {
  const { state, dispatch } = useDatabaseBackedData();
  const params = useParams();

  React.useEffect(() => {
    dispatch({
      type: 'get_activity',
      id: params.id,
    });
  }, [params]);

  const activity = state?.currentActivity;
  const hasActivity = Boolean(activity);

  if (params.id && !hasActivity) {
    return <NotFoundPage />;
  }

  const onSubmit = (e) => {
    e.preventDefault();
    console.log('oof');
  };

  return (
    <div className={[css.activityDeletePage, className || ''].join(' ')}>
      <form onSubmit={onSubmit}>
        <p>Are you sure you want to delete?</p>
        <button type="submit">
          Yes, delete
        </button>
        <Link to={`/activity/${params.id}`}>
          Cancel
        </Link>
      </form>
    </div>
  );
};

export default ActivityDeletePage;
