import Inflection from 'inflection';
import Moment from 'moment';

export const ACTIVITY_ENTRY_TYPES = {
  'yesterday': 'Yesterday',
  'today': 'Today',
  'additional': 'Additional',
  'blocker': 'Blockers',
  'working': 'Working',
};

export const activityEntryInfo = (entryKey, activity, allActivities = []) => {
  let subtitle;
  if (entryKey === 'today') {
    subtitle = Moment(activity.date).format('DD/MM/YYYY');
  } else if (entryKey === 'yesterday') {
    allActivities.sort((a, b) => {
      return b.date.getTime() - a.date.getTime();
    });
    const currentActivityIndex = allActivities.findIndex((ac) => ac.id === activity.id);
    if (allActivities[currentActivityIndex + 1]) {
      subtitle = Moment(allActivities[currentActivityIndex + 1].date).format('DD/MM/YYYY');
    } else {
      subtitle = Moment(activity.date).subtract(1, 'days').format('DD/MM/YYYY');
    }
  }
  return {
    title: ACTIVITY_ENTRY_TYPES[entryKey] || Inflection.capitalize(entryKey),
    subtitle,
  };  
};
