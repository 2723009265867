import React from 'react';

import { NestedTextEntry } from '../../lib/Types';

import * as css from "./EntryDetails.module.scss";

type Props = {
  entries?: NestedTextEntry[];
  className?: string;
};

const EntryDetails: React.FC<Props> = ({ entries = [], className }) => {
  return (
    <ul className={[css.entryDetails, className || ''].join(' ')}>
      { entries.map((item, key) => (
        <li key={key}>
          { item.text }
          { (item.children || []).length > 0 && (
            <EntryDetails entries={item.children} />
          )}
        </li>
      )) }
    </ul>
  );
};

export default EntryDetails;
