import React from 'react';

import { TimeInput } from '../../lib/Types';

import * as css from "./TimeInputEditor.module.scss";

type Props = {
  timeInput?: TimeInput;
  className?: string;
  onChange?: (_timeInput: TimeInput) => void;
};

const TimeInputEditor: React.FC<Props> = ({ timeInput, className, onChange }) => {
  const [currentTimeInput, setCurrentTimeInput] = React.useState(timeInput);

  React.useEffect(() => {
    setCurrentTimeInput(timeInput);
  }, [timeInput]);

  return (
    <div className={[css.timeInputEditor, className || ''].join(' ')}>
      <input
        type="number"
        value={timeInput?.hours || 0}
        onChange={(e) => {
          e.preventDefault();
          const newTimeInput = { ...currentTimeInput, hours: e.target.value };
          setCurrentTimeInput(newTimeInput);
          if (typeof onChange === 'function') {
            onChange(newTimeInput);
          }
        }}
        min={0}
      />
      :
      <input
        type="number"
        value={timeInput?.minutes || 0}
        onChange={(e) => {
          e.preventDefault();
          const newTimeInput = { ...currentTimeInput, minutes: e.target.value };
          setCurrentTimeInput(newTimeInput);
          if (typeof onChange === 'function') {
            onChange(newTimeInput);
          }
        }}
        min={0}
        max={59}
      />
    </div>
  );
};

export default TimeInputEditor;
