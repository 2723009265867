import React from 'react';

import IconButton from '../IconButton';

import { NestedTextEntry } from '../../lib/Types';

import * as css from "./TextEntryChildren.module.scss";

type Props = {
  className?: string;
  index?: number;
  entry?: NestedTextEntry;
  getTextEditor?: (_props: any) => React.ReactNode;
  onChange?: (_entry: NestedTextEntry, _index: number) => void;
  onRemove?: (_index: number) => void;
};

const TextEntryChildren: React.FC<Props> = ({ items = [], getTextEditor, onChange, onRemove, className }) => {
  const [currentItems, setCurrentItems] = React.useState(items);

  React.useEffect(() => {
    setCurrentItems(items);
  }, [items]);

  return (
    <div className={[css.textEntryChildren, className || ''].join(' ')}>
      <ul className={css.list}>
        {(currentItems || []).map((child, index) => (
          <React.Fragment key={index}>
            {getTextEditor({
              child, index, currentItems, setCurrentItems, onChange,
            })}
          </React.Fragment>
        ))}
      </ul>
      <IconButton iconType="add" iconText="Add entry" inline iconSize="sm" onClick={(e) => {
        e.preventDefault();
        setCurrentItems(currentItems.concat({}));
        if (typeof onChange === 'function') {
          onChange(currentItems);
        }
      }} />
    </div>
  );
};

export default TextEntryChildren;
