.activityListPage {
  display: block;
}

.activityList {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  list-style: none;
  margin: 1rem 0;
  padding: 0;
}

.activityItem {
  flex: 1 1 30%;
  margin: 0 0.8rem;
}
